import ExplorerLayout from '../components/layouts/ExplorerLayout'
import styled from '@emotion/styled'
import { useStore } from '../store'
import { useEffect } from 'react'
import Image from 'next/image'
const NotFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotFound = () => {
  const { setNotFound } = useStore()

  useEffect(() => {
    setNotFound(false)
  }, [])

  return (
    <NotFoundContainer>
      <Image
        src="/images/404.png"
        alt="Not Found"
        width={600}
        height={600}
        layout="fixed"
        objectFit="contain"
        quality={100}
        priority
      />
    </NotFoundContainer>
  )
}
NotFound.getLayout = (page) => <ExplorerLayout>{page}</ExplorerLayout>

export default NotFound
